import { lazy } from 'react';
import { createBrowserRouter, RouteObject } from 'react-router-dom';

import { RoutesEnum } from '@/enums';

import { Route } from './components/Route/Route';

// -> Error
const BlockedAccount = lazy(
  () => import('@/pages/BlockedAccount/BlockedAccount'),
);
const Error404 = lazy(() => import('@/pages/404/404'));
// <- Error

// -> Auth
const Login = lazy(() => import('@/pages/Auth/Login/Login'));
const EnterCode = lazy(() => import('@/pages/Auth/EnterCode/EnterCode'));
const ResetPassword = lazy(
  () => import('@/pages/Auth/ResetPassword/ResetPassword'),
);
const SetPassword = lazy(() => import('@/pages/Auth/SetPassword/SetPassword'));
// <- Auth

const Claim = lazy(() => import('@/pages/Claim/Claim'));
const ClaimDocument = lazy(
  () => import('@/pages/Claim/pages/DocumentView/DocumentView'),
);
const ClaimsTable = lazy(() => import('@/pages/ClaimsTable/ClaimsTable'));
const Sales = lazy(() => import('@/pages/Sales/Sales'));
const SalesTable = lazy(() => import('@/pages/SalesTable/SalesTable'));
const Reports = lazy(() => import('@/pages/Reports/Reports'));
const Invoices = lazy(() => import('@/pages/Invoices/Invoices'));
const TransferOwnership = lazy(
  () => import('@/pages/TransferOwnership/TransferOwnership'),
);
const TransferOwnershipDetail = lazy(
  () =>
    import('@/pages/TransferOwnership/pages/TransferDetails/TransferDetails'),
);
const TransferDocument = lazy(
  () => import('@/pages/TransferOwnership/pages/DocumentView/DocumentView'),
);

const privateRoutes: RouteObject[] = [
  {
    path: '/',
    errorElement: <Error404 />,
    children: [
      {
        index: true,
        element: <Route component={Sales} isPrivate />,
      },
      {
        path: RoutesEnum.SALES,
        element: <Route component={SalesTable} isPrivate />,
      },
      {
        path: RoutesEnum.CLAIMS,
        children: [
          {
            index: true,
            element: <Route component={ClaimsTable} isPrivate />,
          },
          {
            path: RoutesEnum.CLAIM,
            children: [
              {
                index: true,
                element: <Route component={Claim} isPrivate />,
              },
              {
                path: RoutesEnum.CLAIM_DOCUMENT_VIEW,
                element: <Route component={ClaimDocument} isPrivate />,
              },
            ],
          },
        ],
      },
      {
        path: RoutesEnum.REPORTS,
        element: <Route component={Reports} isPrivate />,
      },
      {
        path: RoutesEnum.INVOICES,
        element: <Route component={Invoices} isPrivate />,
      },
      {
        path: RoutesEnum.TRANSFER_OWNERSHIP,
        Component: () => <Route component={TransferOwnership} isPrivate />,
        errorElement: <Error404 />,
      },
      {
        path: RoutesEnum.TRANSFER_OWNERSHIP_DETAIL,
        children: [
          {
            index: true,
            element: <Route component={TransferOwnershipDetail} isPrivate />,
          },
          {
            path: RoutesEnum.TRANSFER_OWNERSHIP_DOCUMENT_VIEW,
            element: <Route component={TransferDocument} isPrivate />,
          },
        ],
      },
    ],
  },
];

const publicRoutes: RouteObject[] = [
  {
    path: RoutesEnum.LOGIN,
    element: <Route component={Login} />,
  },
  {
    path: RoutesEnum.ENTER_CODE,
    element: <Route component={EnterCode} />,
  },
  {
    path: RoutesEnum.RESET_PASSWORD,
    element: <Route component={ResetPassword} />,
  },
  {
    path: RoutesEnum.SET_PASSWORD,
    element: <Route component={SetPassword} />,
  },
  {
    path: RoutesEnum.BLOCKED_ACCOUNT,
    element: <Route component={BlockedAccount} />,
  },
];

export const router = createBrowserRouter([...privateRoutes, ...publicRoutes]);
